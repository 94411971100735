import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import axios from 'axios';
import cryptoJS from "crypto-js";
import { useAlert } from 'react-alert';

const Authenticate = () => {
    const isAuthenticated = localStorage.getItem("useruuid");
    const navigate = useNavigate();
    const alert = useAlert();

    const isLogin = () => {
        let useruuid = JSON.parse(localStorage.getItem('useruuid'));

        let StringSignature = '';

        StringSignature += 'WEBSITE';
        StringSignature += 'PASSS';
        StringSignature += useruuid.tableuserindex;
        StringSignature += useruuid.tokenlogin;

        let signature = cryptoJS.HmacSHA256(StringSignature, process.env.REACT_APP_SECRET);

        axios({
            method : 'POST',
            url : process.env.REACT_APP_APIHOST+'/api/auth/v01/islogin',
            headers : {
                platform : 'WEBSITE',
                gtoken : 'PASSS',
                userindex : useruuid.tableuserindex,
                tokenlogin : useruuid.tokenlogin,
                signature : signature,
            }
        }).then(res => {
            if(res.data.status.status === 0){
                localStorage.removeItem('useruuid');
                navigate('/login');
            }
        })
    }

    if(localStorage.getItem('useruuid') !== null){
        isLogin();   
    }

    if(!isAuthenticated){
        alert.error('Please Re-Login');
        return <Navigate to="/login" replace />
    }

    return <Outlet/>
}

export default Authenticate;